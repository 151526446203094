/*General Font*/
@font-face{
  font-family: 'Courier Prime', monospace;
  src: url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
}

* {
  font-family: 'Courier Prime', monospace;
  box-sizing: border-box;
  overflow: hidden;
}

/*Preset Colours*/
:root{
  --comp-col-01:#222323;
  --comp-col-02:#5252ff;
  --comp-col-03:#b5e877;
}

#page-wrapper{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  background-color: #222323;
  background-size: contain;
}

#emoji-button-wrapper{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  z-index: 2;
  pointer-events: none;
}

.EmojiButton{
  position: absolute;
  border: none;
  padding: 0;
  background: no-repeat url('./content/_other/emoji-circle.png');
  background-size: cover;
  cursor: pointer;
  pointer-events: all;
}

#story-display-wrapper{
  position: absolute;
  top:0%;
  left:0%;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: max(2vw, 2vh);
  overflow: auto;
}

.ImagePost{
  display: block;
  max-width: 100%;
  padding-bottom: 0.5vw;
}
.TextPost{
  display: block;
  width: 90%;
  padding-bottom: 0.5vw;
  text-shadow:
   -0.5px -0.5px 0 #FFF,  
    0.5px -0.5px 0 #FFF,
    -0.5px 0.5px 0 #FFF,
     0.5px 0.5px 0 #FFF;
}


#bg-image{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  z-index: 0;
}
#bg-image-canvas{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
}
#info-modal-button{
  position: absolute;
  top: 0%;
  right: 16px;
  z-index: 3;
  background-color: transparent;
  border: none;
  width: 5vw;
  height: 5vw;
  background-image: url("./content/_other/black-heart.png");
  background-size: cover;
  animation-name: heartbeat;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
#info-modal-button-text{
  z-index: 3;
  color: aliceblue;
  font-size: larger;
}
@keyframes heartbeat {
  0% {transform: scale(0.95);}
  40% {transform: scale(1.0);}
  100% {transform: scale(0.95);}
}
#info-modal-button:hover{
  animation-name: '';
  transform: scale(1.05);
}
/* #bg-image{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  transform: rotate(180deg);
  z-index: 0;
}
#bg-image-canvas{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100vw;
  transform: rotate(90deg);
} */

#info-modal-wrapper{
  position: absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  background-color: #222323dd;
  padding: max(4vw, 4vh);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
#info-modal{
  max-width:50%;
  max-height:100%;
  background-color: #222323;
  opacity: 1;
  border-radius: max(1vw, 1vh);
  z-index: 6;
  color: #FFF;
  padding: max(2vw, 2vh);
  overflow: auto;
}
#info-modal-close{
  color: #aaaaaa;
  float: right;
  font-size: 5vw;
  font-weight: bold;
  width:3vw;
  height:3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}
#info-modal-close:hover{
  color: #dddddd;
  cursor: pointer;  
}

/* Mobile Rules */
@media all and (any-hover: none) {
  #info-modal-button{
    width: 10vh;
    height: 10vh;
    right: 1vh;
    top: calc(100% - 11vh);
  }
  #info-modal-wrapper{
    padding: max(1vw, 1vh);
  }
  #info-modal-close{
    font-size: 8vh;
    width:5vh;
    height:5vh;
  }
  #info-modal{
    max-width:100%;
  }
}